<template>
  <b-card title="Groceries Monitoring">
    <l-map style="height: 600px" :zoom="zoom" :center="[-3.797068, 115.370529]" :options="{ scrollWheelZoom: false }">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-geo-json :geojson="geojson" />
    </l-map>

  </b-card>
</template>

<script>
import { BRow, BCol, BLink, BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,BFormSelect} from 'bootstrap-vue'
import { LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
import geoIndonesia from './indonesiajs.json'

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    BCardCode,
    BCardTitle,
    BRow,
    BCol
  },
  data() {
    return {
      url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      zoom: 4.5,
      center: [7.797068, 110.370529],
      geojson: geoIndonesia,
    }
  },
  // async created() {
  //   const response = await fetch('indonesiajs.json')
  //   this.geojson = await response.json()
  //   print(this.geojson)
  // },
}
</script>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
