<template>
  <b-row>
    <b-col cols="4">
      <b-form-group
      label=""
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="btn-radios-2"
        v-model="selectedJenis"
        :options="options"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        size="md"
        name="radio-btn-outline"
        buttons
      ></b-form-radio-group>
    </b-form-group>
    </b-col>

    <b-col cols="4">
    <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        :href="'https://sembako-backend.denican.id/stok_export?tipe_wilayah=semua'"
        
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        <span class="align-middle">Download Excel</span>
      </b-button>
  </b-col>

    <b-col cols="12">
      <b-card title="Groceries Monitoring">
        <l-map ref="myMapRef" style="height: 600px" :zoom="zoom" :center="[-3.797068, 115.370529]" :options="{ scrollWheelZoom: false }">
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-geo-json ref="sample" :geojson="geojson" :options="mapOptions"/>
        </l-map>

      </b-card>
    </b-col>

    <!-- <b-col cols="12">
      <leaflet-geo-jSON />
    </b-col> -->
  </b-row>
</template>

<script>
import { BRow, BCol, BLink, BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,BFormSelect,BFormGroup,BFormRadioGroup, BTable,BButton} from 'bootstrap-vue'
import { LMap, LTileLayer, LGeoJson,LMarker,LPopup } from 'vue2-leaflet'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
import geoIndonesia from './indonesiajs.json'
import LeafletBasic from './LeafletBasic.vue'
import LeafletMarkerCirclePolygon from './LeafletMarkerCirclePolygon.vue'
import LeafletPopup from './LeafletPopup.vue'
import LeafletGeoJSON from './LeafletGeoJSON.vue'
import LeafletGroup from './LeafletGroup.vue'
import LeafletCustomIcon from './LeafletCustomIcon.vue'
import axios from 'axios'

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    BCardCode,
    BCardTitle,
    BRow,
    BCol,
    BCard,
    LPopup,
    LMarker,
    BFormGroup,
    BFormRadioGroup,
    BTable,
    BButton,

    LeafletBasic,
    LeafletMarkerCirclePolygon,
    LeafletPopup,
    LeafletGeoJSON,
    LeafletGroup,
    LeafletCustomIcon,
  },
  data() {
    return {
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      selectedJenis: 'ketersediaan',
      options: [
          { text: 'Ketersediaan', value: 'ketersediaan' },
          { text: 'Harga', value: 'harga' },
      ],
      dataKetersediaanHarga:null,
      dataKeterangan:null,
      url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      zoom: 4.5,
      center: [7.797068, 110.370529],
      geojson: geoIndonesia,
      mapOptions: {
        style: function style(feature) {
          return {
            weight: 4,
            opacity: 0.7,
            color: '#666',
            fillOpacity: 0.3
          };
        },   
        onEachFeature: (feature, layer) => {
          
          //layer.bindPopup('<strong style="color: grey; margin-bottom: 0;">'+ feature.properties.Propinsi +'</strong>');
          var self = this;
          layer.on('click', function(e) {
          self.popupName = feature.properties.Propinsi;
          console.log(feature.properties.Propinsi);
          //open popup
          self.loadData(self.selectedJenis,feature.properties.kode)
          setTimeout(() => {
            if(self.dataKetersediaanHarga == null){
            self.loadData(self.selectedJenis,feature.properties.kode)
            var popup = L.popup()
            .setLatLng(e.latlng) 
            .setContent('<strong style="color: grey; margin-bottom: 0;">'+ feature.properties.Propinsi +'</strong> <div> Tidak Ada Data</div>')
            .openOn(self.$refs.myMapRef.mapObject); 
          }else{
            if(self.selectedJenis == "harga"){
            self.loadData(self.selectedJenis,feature.properties.kode)
            var popup = L.popup()
            .setLatLng(e.latlng) 
            .setContent('<strong style="color: grey; margin-bottom: 0;">'+ feature.properties.Propinsi +'</strong> <table><thead><tr><th scope="col">Jenis</th><th scope="col">Harga</th><th scope="col">Ket</th></tr></thead><tbody><tr><td>Beras</td><td>'+ self.dataKetersediaanHarga[0] +'</td><td>'+ self.dataKeterangan[0] +'</td></tr><tr><td>Minyak Goreng</td><td>'+ self.dataKetersediaanHarga[1] +'</td><td>'+ self.dataKeterangan[1] +'</td></tr><tr><td>Telur</td><td>'+ self.dataKetersediaanHarga[2] +'</td><td>'+ self.dataKeterangan[2] +'</td></tr><tr><td>Gula</td><td>'+ self.dataKetersediaanHarga[3] +'</td><td>'+ self.dataKeterangan[3] +'</td></tr><tr><td>Daging Ayam</td><td>'+ self.dataKetersediaanHarga[4] +'</td><td>'+ self.dataKeterangan[4] +'</td></tr><tr><td>Daging Sapi</td><td>'+ self.dataKetersediaanHarga[5] +'</td><td>'+ self.dataKeterangan[5] +'</td></tr><tr><td>Kedelai</td><td>'+ self.dataKetersediaanHarga[6] +'</td><td>'+ self.dataKeterangan[6] +'</td></tr><tr><td>Cabe Merah</td><td>'+ self.dataKetersediaanHarga[7] +'</td><td>'+ self.dataKeterangan[7] +'</td></tr><tr><td>Bawang Merah</td><td>'+ self.dataKetersediaanHarga[8] +'</td><td>'+ self.dataKeterangan[8] +'</td></tr></tbody></table><div></div>'+ '<br/><a href="#/grafik/pemantauan/harga/'+feature.properties.kode+'">Detail</a>')
            .openOn(self.$refs.myMapRef.mapObject); 
            
            }else{
              self.loadData(self.selectedJenis,feature.properties.kode)
              var popup = L.popup()
              .setLatLng(e.latlng) 
              .setContent('<strong style="color: grey; margin-bottom: 0;">'+ feature.properties.Propinsi +'</strong> <table><thead><tr><th scope="col">Jenis</th><th scope="col">Ketersediaan</th></tr></thead><tbody><tr><td>Beras</td><td>'+ self.dataKetersediaanHarga[0] +'</td></tr><tr><td>Minyak Goreng</td><td>'+ self.dataKetersediaanHarga[1] +'</td></tr><tr><td>Telur</td><td>'+ self.dataKetersediaanHarga[2] +'</td></tr><tr><td>Gula</td><td>'+ self.dataKetersediaanHarga[3] +'</td></tr><tr><td>Daging Ayam</td><td>'+ self.dataKetersediaanHarga[4] +'</td></tr><tr><td>Daging Sapi</td><td>'+ self.dataKetersediaanHarga[5] +'</td></tr><tr><td>Kedelai</td><td>'+ self.dataKetersediaanHarga[6] +'</td></tr><tr><td>Cabe Merah</td><td>'+ self.dataKetersediaanHarga[7] +'</td></tr><tr><td>Bawang Merah</td><td>'+ self.dataKetersediaanHarga[8] +'</td></tr></tbody></table><br/><a href="#/grafik/pemantauan/ketersediaan/'+feature.properties.kode+'">Detail</a>')
              .openOn(self.$refs.myMapRef.mapObject); 
            }
          }
          }, 500);
          
          
          
          });
        },
      
      },
      
    }
  },
  methods:{
    async loadData(jenis,kode){
      console.log(jenis+":"+kode)
      await axios.get('https://sembako-backend.denican.id'+'/stok_chart/'+jenis+'?tipe_wilayah=provinsi&id_wilayah='+kode)
      .then(response =>{
        this.dataKetersediaanHarga = []
        if(jenis == "harga"){
          this.dataKetersediaanHarga = response.data.data.harga
          this.dataKeterangan = response.data.data.status_harga
        }else{
          this.dataKetersediaanHarga = response.data.data.ketersediaan
        }
        
        console.log(this.dataKetersediaanHarga)
        this.$forceUpdate()
      })
    },
    changeRoute(route) {
      // `route` is either a string or object
      console.log(route+"hallo")
      // router.push(route);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
